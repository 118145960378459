

































































import {Component, Prop, Vue} from "vue-property-decorator";
import Address from "@/components/profile/Address.vue";
import PortalAddress from "@/components/profile/branches/PortalAddress.vue";
import CreateReserveDocument from "@/components/profile/company/CreateReserveDocument";
import PortalInput from "@/components/common/PortalInput.vue";
import Workspaces from "@/state/Workspaces";
import Application from "@/state/Application";
import Notifications from "@/state/Notifications";
import {ifValid, processError} from "@/utils/ComponentUtils";
import AddressDTO from "@/dto/AddressDTO";
import DocumentService from "@/services/DocumentService";
import CompanyService from "@/services/CompanyService";

@Component({
	components: {PortalInput, PortalAddress, Address}
})
export default class ReserveAgreementCreationModal extends Vue {
	@Prop({default: function () {return}})
	private onSuccess!: () => void



	private req: CreateReserveDocument = new CreateReserveDocument();

	public created(): void {
		this.req.companyId = Workspaces.getCurrent.id;
		Application.startLoading()
		Promise.all(
			[
				CompanyService.getHeadQuarter().then(
					res => {
						if (res.data?.legalAddress && res.data?.legalAddress?.fullAddress) {
							this.req.address = res.data.legalAddress;
						}
					},
					err => processError(err, this)
				),
				CompanyService.getAllShareHolderByCompanyId(Workspaces.getCurrent.id).then(
					res => {
						if (res.data?.length > 0) {
							this.req.officerName = res.data[0].person.name
							this.req.officerTitle = "President"
						}
					},
					err => processError(err, this)
				)
			]
		)
			.then(
				() => Application.stopLoading()
			)
	}

	private requestReserveAgreement(): void {
		if (AddressDTO.isEmpty(this.req.address)) {
			Notifications.error("Address is mandatory field!")
			return
		}
		ifValid(this, () => {
			Application.startLoading()
			DocumentService.requestReserveAgreement(this.req).then(
				() => {
					Application.stopLoading()
					Notifications.success("The reserve agreement was successfully requested!");
					this.onSuccess();
					this.$modal.hideAll()
				},
				err => processError(err, this)
			)
		})
	}
}

